import { render, staticRenderFns } from "./TeacherTeacherPayments.vue?vue&type=template&id=7d245746&scoped=true&"
import script from "./TeacherTeacherPayments.vue?vue&type=script&lang=js&"
export * from "./TeacherTeacherPayments.vue?vue&type=script&lang=js&"
import style0 from "./TeacherTeacherPayments.vue?vue&type=style&index=0&id=7d245746&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d245746",
  null
  
)

export default component.exports