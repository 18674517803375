<template>
  <div>
    <div v-if="progressStore.progress !== true">
      <!--Title Bar-->
      <title-bar
        :title-value="teachersStore.teacher.d_nameLast + ', ' + teachersStore.teacher.d_nameFirst"
      />

      <!--Function Bar-->
      <teacher-function-bar />

      <!--Teacher Payments List-->
      <teacher-payments :key="teachersStore.teacher.id" :teacherPaymentSection="3" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import TeacherFunctionBar from "@/components/TeacherFunctionBar";
import TeacherPayments from "@/components/TeacherPayments";

export default {
  name: "TeacherTeacherPayments",
  mixins: [mixin],
  computed: {
    ...mapState({
      teachersStore: state => state.teachersStore,
      progressStore: state => state.progressStore
    })
  },
  components: {
    TitleBar,
    TeacherFunctionBar,
    TeacherPayments
  },
  created() {
    this.initialize();
  },
  data() {
    return {};
  },
  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      await this.$store.dispatch("teachersStore/read", this.$route.params.teacherUUID);
      this.$store.dispatch("progressStore/set", false);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
